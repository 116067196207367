"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* This file is a little messy...

First bit is a library that handles most of the funcitonality - menu toggle, gallery, class on scroll, filters and accordion
After library is window/document functions
AJAX/Form stuff is at the end

*/

(function (root, $, undefined) {
  "use strict";

  var GunnerApp = {
    name: "GunnerApp",
    // this init is not really being used, probably redundant.
    init: function init(plugins) {
      plugins.forEach(function (plugin) {
        var _this = $(this);
        GunnerApp[plugin].call(_this);
      });
    },
    Menu: function Menu(_this) {
      var menuplugin = new _Menu(_this);
      menuplugin;
    },
    Gallery: function Gallery(_this, options) {
      var galleryplugin = new _Gallery(_this, options);
      galleryplugin;
    },
    ClassOnScroll: function ClassOnScroll(_this, options) {
      var scrollplugin = new _ClassOnScroll(_this, options);
      scrollplugin;
    },
    Filters: function Filters(_this) {
      var filtersplugin = new _Filters(_this);
      filtersplugin;
    },
    CategoryFilter: function CategoryFilter(_this) {
      var categoryfilterplugin = new _CategoryFilter(_this);
      categoryfilterplugin;
    },
    Accordion: function Accordion(_this) {
      var accordionplugin = new _Accordion(_this);
      accordionplugin;
    }
  };

  var _Accordion = function () {
    function _Accordion(element) {
      _classCallCheck(this, _Accordion);

      this.element = element;
      this._init();
    }

    _createClass(_Accordion, [{
      key: "_init",
      value: function _init() {
        var _this = this;
        var _panels = $(this.element).find('.js-accordion-panel');

        _panels.each(function () {
          var link = $(this).find('.js-accordion-toggle');

          link.on('click', function (e) {
            e.preventDefault();
            var parent = link.parent();
            if (!parent.hasClass('is-active')) {
              _panels.removeClass('is-active');
              parent.addClass('is-active');
            }
          });
        });
      }
    }]);

    return _Accordion;
  }();

  var _Menu = function () {
    function _Menu(element, options) {
      _classCallCheck(this, _Menu);

      this.element = element;
      this._init();
      this.body = $('body');
    }

    _createClass(_Menu, [{
      key: "_init",
      value: function _init() {
        var activeClass = 'menu-is-active';
        var navToggle = $(this.element);
        var _this = this;

        navToggle.on('click', function (e) {
          e.preventDefault();
          _this._toggle(activeClass);
        });
      }
    }, {
      key: "_toggle",
      value: function _toggle(activeClass) {
        var _this = this;
        if (this.body.hasClass(activeClass)) {
          _this._closeNav(activeClass);
        } else {
          _this._openNav(activeClass);
        }
      }
    }, {
      key: "_openNav",
      value: function _openNav(activeClass) {
        this.body.addClass(activeClass);
      }
    }, {
      key: "_closeNav",
      value: function _closeNav(activeClass) {
        this.body.removeClass(activeClass);
      }
    }, {
      key: "destroy",
      value: function destroy() {
        // don't think this is needed
      }
    }]);

    return _Menu;
  }();

  // Gallery depends on Slick. We could just init Slick on page load but this way makes things a bit...Slicker. //

  var _Gallery = function () {
    function _Gallery(element, options) {
      _classCallCheck(this, _Gallery);

      this.element = element;
      this.options = $.extend({}, _Gallery.defaults, options);
      this.body = $('body');
      this.slider = $(this.element).find(".js-slider");
      this._init();
    }

    _createClass(_Gallery, [{
      key: "_init",
      value: function _init() {
        var activeClass = 'gallery-is-active';
        var galleryToggle = $('.js-gallery-toggle');
        var _this = this;

        galleryToggle.on('click', function () {
          _this._toggle(activeClass);
        });

        galleryToggle.keypress(function (e) {
          if (e.which === 32) {
            e.preventDefault();
            _this._toggle(activeClass);
          }
        });

        $(document).on('keydown', function (e) {
          //console.log(e.keyCode + ' pressed');
          if (e.keyCode == 37) {
            $(this.slider).slick('slickPrev');
            //console.log('pressed');
          }
          if (e.keyCode == 39) {
            $(this.slider).slick('slickNext');
            //console.log('pressed');
          }
        });

        $(this.slider).slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          fade: true,
          dots: false,
          speed: 2000,
          autoplay: true,
          autoplaySpeed: 4000,
          mobileFirst: true,
          responsive: [{
            breakpoint: 768, // get correct drop px
            settings: {
              arrows: true,
              dots: true
            }
          }]
        });

        $('.js-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          var contentToHide = $('.js-hero-content-to-hide');

          // Hide the inner content if we're not on initial slide.
          if (nextSlide > 0) {
            contentToHide.addClass('inner--hidden');
          } else {
            contentToHide.removeClass('inner--hidden');
          }
        });
      }
    }, {
      key: "_toggle",
      value: function _toggle(activeClass) {
        var _this = this;
        if (this.body.hasClass(activeClass)) {
          _this._closeGallery(activeClass);
        } else {
          _this._openGallery(activeClass);
        }
      }
    }, {
      key: "_openGallery",
      value: function _openGallery(activeClass) {
        this.body.addClass(activeClass);
        $(window).trigger('resize');
        $(this.slider).resize();
        $(this.slider).slick('setDimensions');
        $(this.slider).slick('getSlick').refresh();
        var SlideWidth = $(document).find('.slick-slide').outerWidth();
        //console.log('first' + SlideWidth);
      }
    }, {
      key: "_closeGallery",
      value: function _closeGallery(activeClass) {
        this.body.removeClass(activeClass);
      }
    }]);

    return _Gallery;
  }();

  _Gallery.defaults = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    dots: false,
    speed: 500
  };

  var _ClassOnScroll = function () {
    function _ClassOnScroll(element, options) {
      _classCallCheck(this, _ClassOnScroll);

      this.element = $(element);
      var opt = this.element.attr('data-gunner-options');
      if (opt) {
        opt = opt.split(',').reduce(function (acc, cur) {
          var data = cur.split(':');
          acc[data[0]] = data[1];
          return acc;
        }, {});
      }
      this.options = $.extend({}, _ClassOnScroll.defaults, opt);
      this._init();
    }

    _createClass(_ClassOnScroll, [{
      key: "_init",
      value: function _init() {
        var _this = this;
        var _window = $(window);
        var _offset = this.options.offset;
        var _activeclass = this.options.activeclass;

        // we might want the offset to be based off another element
        if (this.options.reference) {
          var _reference = this.options.reference;
          if ($(_reference).length > 0) {
            _offset = $(_reference).outerHeight() - this.element.outerHeight() * 2;
          }
        }

        var trigger = throttle(function () {
          var scroll = _window.scrollTop();
          if (scroll >= _offset) {
            _this._AddClassonScroll(_activeclass);
          } else {
            _this._RemoveClassonScroll(_activeclass);
          }
        }, 50);

        _window.scroll(function () {
          trigger();
        });
      }
    }, {
      key: "_AddClassonScroll",
      value: function _AddClassonScroll(_activeclass) {
        this.element.addClass(_activeclass);
      }
    }, {
      key: "_RemoveClassonScroll",
      value: function _RemoveClassonScroll(_activeclass) {
        this.element.removeClass(_activeclass);
      }
    }]);

    return _ClassOnScroll;
  }();

  _ClassOnScroll.defaults = {
    offset: 500,
    activeclass: 'element-in-view'
  };

  var _Filters = function () {
    function _Filters(element, options) {
      _classCallCheck(this, _Filters);

      this.element = element;
      this.options = $.extend({}, _Filters.defaults, options);
      this._init();
    }

    _createClass(_Filters, [{
      key: "_init",
      value: function _init() {
        var _this = this;
        var filterElement = $(this.element).attr('data-filter-element');
        var parent = $(this.element);
        var filtered = {};
        var joined = '';

        $('.filters__list').each(function () {
          var $this = $(this);
          var $type = $this.data('filter-type');
          var filterType = $(this).attr('data-filter-type');

          var currentURL = window.location.href;
          var regex = new RegExp(filterType + '-[^\,]+');
          var found = currentURL.match(regex);

          if (found) {
            // If there is a hash, split at the hash and remove the end.
            var currentFilter = found[0].replace(filterType + '-', '').split('#')[0];
          }

          if (currentFilter) {
            $this.find('option').each(function (index, list) {
              $(this).removeAttr('selected');

              if ($(this).val() === currentFilter) {
                $(this).attr('selected', '');
              }
            });
          }

          // Grab the value of input after we've configured the DOM from URL.
          var $val = $this.val();

          filtered[$type] = $val || {};
          joined = Object.keys(filtered).reduce(function (acc, filter) {
            acc.push(filter + '-' + filtered[filter]);
            return acc;
          }, []);
        });

        // console.log(joined);

        $('.filters__list').on('change', function (e) {
          var $this = $(this);
          var $val = $this.val();
          var $type = $this.data('filter-type');
          var minCount = parent.find('select').length;

          filtered[$type] = $val || {};

          joined = Object.keys(filtered).reduce(function (acc, filter) {
            acc.push(filter + '-' + filtered[filter]);
            return acc;
          }, []);

          // console.log(joined);

          var path = '?filter=' + joined;
          history.pushState(path, "page 2", path);

          $('.' + filterElement).find('li').each(function (index, list) {
            var hasAllClasses = joined.every(function (item) {
              return list.classList.contains(item);
            }) && joined.length === minCount;

            if (hasAllClasses) {
              // console.log('yes');
              list.classList.remove('inactive');
              list.classList.add('active');
            } else {
              // console.log(joined);
              list.classList.remove('active');
              list.classList.add('inactive');
            }
          });
        });
      }
    }]);

    return _Filters;
  }();

  _Filters.defaults = {};

  var _CategoryFilter = function () {
    function _CategoryFilter(element, options) {
      _classCallCheck(this, _CategoryFilter);

      this.element = element;
      this._init();
    }

    _createClass(_CategoryFilter, [{
      key: "_init",
      value: function _init() {
        var _this = this;
        var _dropdown = $(this.element).find('select');

        $(_dropdown).on('change', function (e) {
          _this._onCatChange(_dropdown);
        });
      }
    }, {
      key: "_onCatChange",
      value: function _onCatChange(_dropdown) {
        location.href = $(_dropdown).val();
      }
    }]);

    return _CategoryFilter;
  }();

  window.GunnerApp = GunnerApp;
})(undefined, jQuery);

GunnerApp.invoke = function () {
  var nodes = document.querySelectorAll('[data-gunner-js]'),
      node,
      func;

  for (var i = 0, j = nodes.length; i < j; i++) {
    node = nodes[i];
    func = node.getAttribute('data-gunner-js');
    if (this[func]) {
      //console.log('fired '+func);
      this[func](node);
    }
  }
};

// Utilities
function throttle(callback, limit) {
  var wait = false; // Initially, we're not waiting
  return function () {
    // We return a throttled function
    if (!wait) {
      // If we're not waiting
      callback.call(); // Execute users function
      wait = true; // Prevent future invocations
      setTimeout(function () {
        // After a period of time
        wait = false; // And allow future invocations
      }, limit);
    }
  };
}

function debounce(func, wait, immediate) {
  var timeout;

  return function () {
    var context = this,
        args = arguments;
    var later = function later() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

function guid() {
  var d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
  });
  return uuid;
}

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

jQuery(document).on('ready', function () {
  GunnerApp.invoke();

  jQuery('.input__field').on('blur', function () {
    if (!jQuery(this).val()) {
      jQuery(this).parent('span').parent('.input').removeClass('input--focus');
    }
  }).on('focus', function () {
    jQuery(this).parent('span').parent('.input').addClass('input--focus');
  });
});

var navBar = document.getElementById('siteHeader');
var mainBody = document.getElementById('siteMain');
var mainBodyScroll;
window.onscroll = function changeNav() {
  mainBodyScroll = mainBody.getBoundingClientRect().top;

  //this.console.log(mainBodyScroll);
  if (mainBodyScroll < -100) {
    navBar.classList.add('is-in-view');
  } else {
    navBar.classList.remove('is-in-view');
  }
};

// Hide the gallery button until page is loaded and gallery is ready
jQuery(window).load(function () {
  jQuery('.button--gallery').css('visibility', 'visible');
});

(function (root, $, undefined) {
  "use strict";

  $(document).on('ready', function () {
    $(".js-post-video").each(function () {

      var iframe = $(this).siblings('div').html();
      var iframe_src = $(iframe).attr("src") + '?autoplay=1';
      var iframe_title = $(iframe).attr("title");
      var newframe = $(iframe).attr('src', iframe_src);

      $(this).siblings('div').empty();
      $(this).attr('data-title', iframe_title);

      $(this).click(function () {
        $(this).addClass('playing');
        $(this).siblings('div').empty().append(newframe);
      });
    });
  });

  var cloneIndex = 2;

  $(".js-add-pax").click(function () {

    $(".passenger-entry-1").last().clone().appendTo($(".js-fields")).removeClass('passenger-entry-1').addClass('passenger-entry-' + cloneIndex).find("input").each(function () {
      var name = $(this).attr('name');
      $(this).val('');
    });

    cloneIndex++;
    return false;
  });

  $(document).on("click", ".js-remove-pax", function (e) {
    var parent = $(this).parent();
    parent.remove();
    e.preventDefault();
  });

  // conditional checkboxes

  $('.toggle-required').each(function () {
    //console.log($(this));
    $(this).click(function () {
      var childInputs = $(this).parent().parent('fieldset').find('input[type=text]');

      if (childInputs.attr('required')) {
        childInputs.prop("required", false).prop("disabled", true).removeClass('has-error').next(".input-error").remove();
      } else {
        childInputs.prop("required", true).prop("disabled", false);
      }
    });
  });

  // switch validation on passport
  $('.js-passport-upload').on('change', function (e) {
    //console.log('change');
    var value = $(this).val();
    var $parent = $(this).closest('.js-passenger-fields').find('.js-passport-fields');
    if (value) {
      $parent.find('.data-input').removeAttr('required');
      $parent.find('.data-input').removeClass('has-error');
      $parent.find('span.input-error').remove();
    } else {
      $parent.find('.data-input').attr('required', 'required');
    }
  });

  $(".js-booking-request").on('submit', function (e) {

    var ajaxURL = MyAjax.ajaxurl;
    var $this = $(this);
    var data = $this.serialize();
    var data = new FormData(document.getElementById("bookingForm"));
    var errors = false;
    var emailReg = /^([a-zA-Z0-9\\.]+)@([a-zA-Z0-9\\-\\_\\.]+)\.([a-zA-Z0-9]+)$/i;

    $(".message").empty();
    $this.find('.js-submit').prop("disabled", true);

    // basic validation

    $this.find('.data-input').filter('[required]:visible').each(function () {
      var inputname = $(this).attr('data-label');
      $(this).removeClass('has-error').next(".input-error").remove();
      // if inputs are empty
      if ($(this).attr('type') != 'email' && $(this).val() === "") {
        $(this).addClass('has-error').after("<span class='input-error'>Please fill in " + inputname + "</span> ");
        errors = true;
      }
      // email validate
      if ($(this).attr('type') == 'email') {
        if (!emailReg.test($(this).val())) {
          //console.log('email error');
          $(this).addClass('has-error').after("<span class='input-error'>Please specify a valid email</span> ");
          errors = true;
        }
      }
    });

    if (errors) {

      $('.js-message').addClass('message').empty().append('<p>Your form has errors. Please scroll up and check the required fields.</p>');
      $this.find('.js-submit').prop("disabled", false);
      $('html, body').animate({
        scrollTop: $('.input-error:visible:first').offset().top - 250
      }, 1000);
    } else {

      $.ajax({
        type: 'POST',
        url: ajaxURL,
        data: data,
        processData: false,
        contentType: false,
        enctype: 'multipart/form-data',
        success: function success(response) {

          if (response == 1) {
            $this.removeClass('form--fail').addClass('form--success');
            $('.js-message').empty().addClass('message').append('<p>Thank you for submitting your booking form. We will be in touch with you to confirm that we have received it.</p>');
          } else {
            var jsonData = JSON.parse(response);
            $('.js-message').empty();
            $.each(jsonData, function () {
              //console.log(jsonData.length);
              $('.js-message').addClass('message').empty();
              for (var i = 0; i < jsonData.length; i++) {
                //console.log(i);
                $('.js-message').append('<p>' + jsonData[i][0] + '</p>');
              }
            });

            $this.find('.js-submit').prop("disabled", false);
            $this.removeClass('form--success').addClass('form--fail');
          }
          return false;
        }
      });
    }

    e.preventDefault();
  });

  var brochureSubmitting = false;
  $(".js-brochure-request").on('submit', function (e) {
    e.preventDefault();
    if (!brochureSubmitting) {
      brochureSubmitting = true;
      var brochure_email = $(this).find('#brochureemail').val();
      var ajaxURL = MyAjax.ajaxurl;
      var $this = $(this);

      var data = {
        action: "brochure_request",
        toemail: brochure_email
      };

      $.ajax({
        type: 'POST',
        url: ajaxURL,
        data: data,
        success: function success(response) {
          if (response === 1) {
            $this.removeClass('form--success').addClass('form--fail');
          } else {
            $this.removeClass('form--fail').addClass('form--success');
          }
          setTimeout(function () {
            brochureSubmitting = false;
          }, 1000);
          return false;
        }
      });
    }
  });

  $('.js-pagejump').on('change', function () {
    var url = $(this).val(); // get selected value
    if (url) {
      // require a URL
      window.location = url; // redirect
    }
    return false;
  });

  // Search in header
  $('.js-search-toggle').on('click', function (e) {
    e.preventDefault();
    setTimeout(function () {
      $('.overlay__search__input').focus();
    }, 301); // Can't focus a transitioning icon.
    $('.js-search-wrap').toggleClass('searchOpen');
  });

  $('.overlay__search__input').on('blur', function () {
    $('.js-search-wrap').removeClass('searchOpen');
  });
})(undefined, jQuery);