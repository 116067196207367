'use strict';

(function (root, $, undefined) {
    "use strict";

    var countryData = null;
    var currentCountry = $('.js-curr-country').attr('data-country');

    $(".map").find('[data-map-country=\'' + currentCountry + '\']').addClass('map__country--current');
    $(".maplist").find('[data-map-country=\'' + currentCountry + '\']').addClass('maplist__country--current');
    if (currentCountry === "Seychelles") {
        $(".js-seychelles").addClass('seychelleshighlight--hover');
    }

    $.get(MyAjax.ajaxurl, {
        action: 'get_countries',
        dataType: 'json'
    }, function (response) {
        // console.log(response);
        countryData = JSON.parse(response);

        $.each(countryData, function (key, value) {
            var activecountry = value.country;
            var permalink = value.permalink;
            $(".map").find('[data-map-country=\'' + activecountry + '\']').addClass('map__country--active').click(function () {
                window.location.href = permalink;
            });
        });
    });

    $(".js-map-click").each(function () {
        var country = $(this).attr('data-map-country');
        $(this).hover(function () {
            $(".map").find('[data-map-country=\'' + country + '\']').toggleClass('map__country--hover');
            $(".maplist").find('[data-map-country=\'' + country + '\']').toggleClass('maplist__country--active');
            if (country === "Seychelles") {
                $(".js-seychelles").toggleClass('seychelleshighlight--hover');
            }
        });
    });

    function countryNames() {
        $(".js-default-map .js-map-click").each(function () {
            var country = $(this).attr('data-map-country');
            var top = $(this).position().top + 10;
            var left = $(this).position().left + 10;
            var width = $(this).width();
            var height = $(this).height();
            var country = $(this).data('map-country');

            if (!navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
                $(this).on('mouseenter', function (e) {
                    $('body').append('<div class="map__country-name"><span>' + country + '</span></div>');
                    $('.map__country-name').hide().fadeIn().css({ 'position': "absolute", 'top': top, "left": left });
                });
                $(this).on('mouseleave', function () {
                    $('.map__country-name').fadeOut().remove();
                });
            }
        });
    }
    // function countryNamesMiniMap() {
    //     $(".js-mini-map .js-map-click").each(function () {
    //         var country = $(this).data('map-country');
    //         var top = $(this).position().top + 10;
    //         var left = $(this).position().left + 10;

    //         if (!navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
    //             $(this).on('mouseenter', function (e) {
    //                 $('body').append('<div class="map__country-name"><span>' + country + '</span></div>');
    //                 $('.map__country-name').hide().fadeIn().css({ 'position': "absolute", 'top': top, "left": left });
    //             });
    //             $(this).on('mouseleave', function () {
    //                 $('.map__country-name').fadeOut().remove();
    //             });
    //         }
    //     });
    // }

    // Wait for full page load to init or calculations are wrong.
    $(window).load(function () {
        countryNames();
        // countryNamesMiniMap();
    });

    $(window).on('resize', function () {
        countryNames();
        // countryNamesMiniMap();
    });

    var callback = function callback(entries, observer) {
        entries.forEach(function (entry) {
            if (entry.intersectionRatio > 0) {
                countryNames();
            }
        });
    };

    var observer = new IntersectionObserver(callback);
    var targets = document.querySelectorAll(".js-intersection-map");

    targets.forEach(function (target) {
        observer.observe(target);
    });
})(undefined, jQuery);